const Colors = {
  divider: 'rgba(0, 0, 0, 0.1)',
  text: '#333333',
  tertiaryText: '#6a6c6e',
  secondaryText: '#a5a5a5',
  disabled: 'rgba(0, 0, 0, 0.12)',
  disabledText: 'rgba(0, 0, 0, 0.26)',
  accent36: '#002bb9',
  primary: '#002bb9',
  accentLight66: '#527aff',
  accentLight92: '#d6e0ff',
  accentSelected66: '#527aff',
  accentHover72: '#7092ff',
  white: '#e4e4e4',
  trueWhite: '#ffffff',
  hint: '#6c757d',
  border: '#979797',
  confirmation: '#43A047',
  error: {
    main: '#d32f2f',
    dark: '#8e1d1d',
  },
  shadow: {
    small: '0 4px 22px 0 #dddddd',
    medium:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  } as const,
  hexToRGBA: (color: string, opacity: number): string => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
  },
};

export default Colors;
