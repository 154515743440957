import { CircularProgress } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

const styles = () => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type Props = {
  className?: string,
};

type AllProps = Props & WithStyles<typeof styles>;

class Loading extends Component<AllProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <div className={`${classes.root} ${className}`}>
        <CircularProgress color="primary" />
      </div>
    );
  }
}

const componentWithStyles = withStyles(styles)(Loading);

export default componentWithStyles;
