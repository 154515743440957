import PriceMarginInput from 'src/main/domain/input/PriceMarginInput';

export default class PriceMargin {
  public readonly __typename: string = 'PriceMargin';

  public readonly name: string;
  public readonly ratio: string;

  constructor(name: string = 'Default', ratio: string = '1.00') {
    this.name = name;
    this.ratio = ratio;
  }

  public static fromInput(priceMargin: PriceMarginInput) {
    return new PriceMargin(priceMargin.name, priceMargin.ratio.toString());
  }
}
