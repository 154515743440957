import React, { Component } from 'react';
import Loadable from 'react-loadable';
import Loading from 'src/shared/components/Loading';

const LoadableComponent = Loadable({
  loader: () => import('../navigation/Router'),
  loading: () => <Loading />,
});

class Startup extends Component {
  public render() {
    return (
      <LoadableComponent />
    );
  }
}

export default Startup;
