/* eslint-disable no-template-curly-in-string,camelcase,@typescript-eslint/camelcase */
import { Resource } from 'i18next';

const translations: Resource = {
  en: {
    general: {
      date: '{{value, L}}',
      french: 'French',
      disconnect: 'Log Out',
      email: 'Email Address',
      password: 'Password',
      login: 'Login',
      invalidCredentials: 'Invalid email or password',
      english: 'English',
      saveUpdates: 'Save updates',
      confirm: 'Confirm',
      quickAdd: 'Quick add',
      modifyEstimate: 'Modify estimate',
      saveEstimate: 'Save estimate',
      submitEstimate: 'Add to cart',
      finalizeOrder: 'Update cart',
      seeInCart: 'See cart',
      deleteEstimate: 'Delete estimate',
      total: 'Total',
      price: '${{value, price}}',
      unitPrice: 'Unit price',
      back: 'Back',
      next: 'Next',
      noResultsFound: 'No results found',
      search: 'Search',
      number: '{{value, FOR_LOCALE}}',
      notFixedDigitsNumber: '{{value, notFixedDigitsNumber}}',
      minMax: 'The value must be between {{min}} and {{max}} {{unit}}',
      meters: 'Meters',
      feet: 'Feet',
      inchFraction: '{{value, inchFraction}}',
      inchFractionLower: '{{value, inchFractionLower}}',
      inchFractionUpper: '{{value, inchFractionUpper}}',
      confirmNavigation: 'Confirm navigation',
      yes: 'Yes',
      no: 'No',
      measure: '{{value, measure}}',
      measureInchesMeters: '{{value, measureInchesMeters}}',
      measureFeetMeters: '{{value, measureFeetMeters}}',
      settings: 'Settings',
      tutorial: 'Tutorial',
      tutorialUrl: 'https://youtu.be/Z5Wo-gVieg8',
      cancel: 'Cancel',
      save: 'Save',
      default: 'Default',
      internalServerError: 'Internal server error',
    },
    estimates: {
      estimate: 'Estimate',
      saved: 'Saved',
      submitted: 'Submitted',
      cancelled: 'Cancelled',
      yourEstimates: 'Your Estimates',
      bundleCount: 'Bundle count',
      shippingDate: 'Shipping date',
      orderNumber: 'Order #',
      orderDate: 'Order date',
      lastUpdate: 'Last update',
      newEstimate: 'New estimate',
      estimateCreation: 'Estimate Creation',
      newBundle: 'New bundle',
      bundle: 'Bundle',
      bundleWithCount: '{{count}} bundle',
      bundleWithCount_plural: '{{count}} bundles',
      profile: 'Profile',
      noSavedEstimates: 'You currently have no saved estimates',
      noSubmittedEstimates: 'You currently have no submitted estimates',
      estimateNotFound: 'Estimate not found',
      profileBundleTotal: 'Profile\'s price',
      bundleTotal: 'Bundle\'s total price',
    },
    builder: {
      addToBundle: 'Add to bundle',
      accessories: 'Accessories',
      manufacturerNotes: 'Manufacturer\'s notes',
      accessoriesTypes: 'Types of accessories',
      addAccessory: 'Add an accessory',
      addToCart: 'Add to cart',
      addTransformerType: 'Add a type',
      aluminumProfiles: 'Aluminum Profiles',
      changeProfile: 'Change profile',
      changeLedStrip: 'Change LED strip',
      presentation: 'Presentation',
      specifications: 'Specifications',
      noSpecification: 'No specification',
      documentations: 'Documentation(s)',
      noDocumentation: 'No documentation',
      noPresentation: 'No presentation',
      downloadDocumentation: 'Download Documentation',
      createBundle: 'Create bundle',
      ledStrip: 'Strips',
      ledStripQuantityTip: 'For larger profiles, consider adding more led strips. The compatibility is validated before proceeding.',
      selectForProfile: 'Select for profile',
      addLedStrip: 'Add a strip',
      addProfile: 'Add a profile',
      quantity: 'Quantity',
      transformer: 'Transformer',
      transformers: 'Transformers',
      selectTransformer: 'Select a transformer',
      priceAndTitle: 'Price: ${{value, price}}',
      unitPriceAndTitle: 'Unit price: {{value, price}}$',
      unitPriceAndTitleShort: 'Unit price: {{value, price}}$',
      defaultNote: 'Write extra information for Titanium here.',
      labor: 'Labor',
      laborAndHandling: 'Labor and handling',
      productionTime: 'Production time',
      productionPower: 'Bundle Power',
      power: 'Power',
      mountedInBox: 'Mounted in box',
      standard: 'Standard',
      dimmable: 'Dimmable',
      plugin: 'Plugin',
      interior: 'Interior',
      exterior: 'Exterior',
      waterproof: 'Waterproof',
      optionalBoxAvailable: 'Optional box available',
      profileLength: 'Profile length',
      innerLength: 'Inner length',
      outerLength: 'Outer length',
      inches: 'Inches',
      millimeters: 'Millimeters',
      feet: 'Feet',
      unitShort_ft: 'ft',
      unitShort_in: 'in',
      unitShort_mm: 'mm',
      unitShort_m: 'm',
      unit: 'Unit',
      noTransformerResults: 'No transformers that matches your criteria were found',
      powerCable: 'Power cable',
      length: 'Length',
      builtInBox: 'Built in box',
      noBox: 'No box',
      approximate: 'Approximate',
      voltage: 'Voltage',
      ip: 'IP',
      color: 'Color',
      watt: 'Watt',
      noAccessoriesForProfile: 'This profile has no accessories',
      updateBundle: 'Update bundle',
      updateProfile: 'Update profile',
      enterProductCodeOrDescription: 'Enter product code or product description',
      cableExit: 'Cable exit',
      cableExitDescription: 'Select the exit point of the cable of your profile',
      extremity: 'Extremity',
      under: 'Under',
      front: 'Front',
      back: 'Back',
      viewFromTheLens: 'View from the lens',
      buildProfile: 'Build your profile',
      finalizeEstimate: 'Finalize the estimate',
      finalizeEstimateInCart: 'Finalize the estimate in cart',
      cancel: 'Cancel',
      includeTransformer: 'Include transformer',
      buildingBundleNumber: 'Building bundle #{{value}}',
      buildingProfileNumber: 'Building profile #{{value}}',
      addPowerCable: 'Add a power cable',
      summary: 'Summary',
      addTransformer: 'Add a transformer',
      notAddingTransformer: 'No transformer will be added to the bundle',
      selectAndAddPowerCables: 'Select and add a power cable',
      cableLengthDescription: 'Specify the length of the cable connecting the transformer to the profile.',
      addAnotherProfile: 'Add another profile',
      selectAndAddTransformer: 'Select and add a transformer',
      selectProfile: 'Select profile',
      selectLedStrips: 'Select led strips',
      selectPowerCable: 'Select power cable',
      emptyEstimate: 'Estimate is empty',
      addAPowerCable: 'Add a power cable',
      navigationEstimateDiscard: 'Navigating away from this page will discard the current estimate. Do you want to proceed?',
    },
    cart: {
      client: 'Client',
      referenceClientName: 'Client name',
      referenceNumber: 'Reference number',
      shippingMode: 'Shipping mode',
      carrierName: 'Carrier name',
      accountNumber: 'Account number',
      addToBill: 'Add to my bill',
      shopPickup: 'Pickup in-store',
      myCarrier: 'My carrier',
      shipToClient: 'Ship to client',
      shippingPayment: 'Shipping payment',
      paymentOption: 'Payment option',
      requestedShippingDate: 'Requested shipping date',
      billToAccount: 'Bill to account',
      payableOnReception: 'Payable on reception',
      payableOnReception5Percent: 'Payable on reception 5%',
      orderSummary: 'Order summary',
      items: 'Items',
      discount: 'Discount',
      shipping: 'Shipping',
      subTotal: 'Subtotal',
      GST: 'GST',
      QST: 'QST',
      shippingDestination: 'Shipping destination',
      myAddress: 'My address',
      otherAddress: 'Other address',
      address: 'Address',
      postalCode: 'Postal code',
      city: 'City',
      provinceState: 'Province/State',
      country: 'Country',
      addBundle: 'Add a bundle',
      deliveryDate: 'Delivery date',
      noPreferences: 'No preferences',
      approximateDate: 'Approximate date',
      downloadPurchaseOrder: 'Download purchase order',
      sendPurchaseOrderTo: 'Send purchase order to',
      sendPurchaseOrder: 'Send purchase order',
      applyPriceMargin: 'Apply price margin',
      email: 'Email',
      note: 'Note',
      invalidDateMessage: 'Invalid date format',
      maxDateMessage: 'Date should not be after maximal date',
      minDateMessage: 'Date should not be before minimal date',
    },
    error: {
      ledStripVoltageMismatch: 'Two or more LED strips have different voltages',
      ledStripWidthExcess: 'The total width of the selected LED strips exceeds the inner width of the selected profile',
      noCompatiblePowerCable: 'No compatible power cable was found for the LED strip {{productCode}}',
      outOfRangeError: 'The value is out of range',
      invalidProductCode: 'The product code is invalid',
      ledStripPowerCableMismatch:
        'There is an inconsistency between the amount of ribbon and the amount of power cable',
      unknownStep: 'Unknown step',
      unknownError: 'Unknown error',
      invalidEmail: 'Invalid email',
      unavailableProduct: 'This product is not available anymore',
      removingUnavailableProduct: 'This product is not available anymore and will be removed from your cart',
    },
    placeholder: {
      address: 'Ex: 837 rue Saint-Joseph Est',
      postalCode: 'Ex: G1K 3C8',
      city: 'Ex: Quebec',
      provinceState: 'Ex: Quebec',
      country: 'Ex: Canada',
      carrierName: 'Ex: FedEx',
      numberMask: '###############',
    },
    settings: {
      ratio: 'Ratio',
      name: 'Name',
      profit: 'Profit',
      addPriceMargin: 'Add a price margin',
      selectedPriceMargin: 'Selected price margin',
      managePriceMargins: 'Manage price margins',
      changesSaved: 'Your changes have been saved',
      updatedToDefault: 'Your selected price margin was deleted; it has been changed to the default option',
    },
  },
  fr: {
    general: {
      date: '{{value, L}}',
      disconnect: 'Déconnexion',
      french: 'Français',
      english: 'Anglais',
      email: 'Adresse courriel',
      login: 'Connexion',
      invalidCredentials: 'Adresse courriel ou mot de passe invalide',
      password: 'Mot de passe',
      saveUpdates: 'Sauvegarder les mises à jour',
      confirm: 'Confirmer',
      quickAdd: 'Ajout rapide',
      modifyEstimate: 'Modifier la soumission',
      saveEstimate: 'Enregistrer la soumission',
      submitEstimate: 'Ajouter au panier',
      finalizeOrder: 'Mettre à jour le panier',
      seeInCart: 'Voir le panier',
      deleteEstimate: 'Supprimer la soumission',
      total: 'Total',
      price: '{{value, price}}$',
      unitPrice: 'Prix unitaire',
      back: 'Précédent',
      next: 'Suivant',
      noResultsFound: 'Aucun résultat trouvé',
      search: 'Recherche',
      number: '{{value, FOR_LOCALE}}',
      notFixedDigitsNumber: '{{value, notFixedDigitsNumber}}',
      minMax: 'La valeur doit être entre {{min}} et {{max}} {{unit}}',
      meters: 'Mètres',
      feet: 'Pieds',
      inchFraction: '{{value, inchFraction}}',
      inchFractionLower: '{{value, inchFractionLower}}',
      inchFractionUpper: '{{value, inchFractionUpper}}',
      confirmNavigation: 'Confirmer la navigation',
      yes: 'Oui',
      no: 'Non',
      measure: '{{value, measure}}',
      measureInchesMeters: '{{value, measureInchesMeters}}',
      measureFeetMeters: '{{value, measureFeetMeters}}',
      settings: 'Paramètres',
      tutorial: 'Tutoriel',
      tutorialUrl: 'https://youtu.be/Z5Wo-gVieg8',
      cancel: 'Annuler',
      save: 'Sauvegarder',
      default: 'Défaut',
      internalServerError: 'Erreur serveur interne',
    },
    estimates: {
      estimate: 'Estimation',
      saved: 'Sauvegardées',
      submitted: 'Soumises',
      cancelled: 'Annulées',
      yourEstimates: 'Vos estimations',
      bundleCount: 'Nombre d\'ensembles',
      shippingDate: 'Date livraison',
      orderNumber: '# de commande',
      orderDate: 'Date commande',
      lastUpdate: 'Dernière mise à jour',
      newEstimate: 'Nouvelle estimation',
      estimateCreation: 'Création d\'une nouvelle estimation',
      newBundle: 'Nouvel ensemble',
      bundle: 'Ensemble',
      bundleWithCount: '{{count}} ensemble',
      bundleWithCount_plural: '{{count}} ensembles',
      profile: 'Profilé',
      noSavedEstimates: 'Vous n\'avez présentement aucune estimation sauvegardée',
      noSubmittedEstimates: 'Vous n\'avez présentement aucune estimation soumise',
      estimateNotFound: 'Estimé introuvable',
      profileBundleTotal: 'Total du profilé',
      bundleTotal: 'Total de l\'ensemble',
    },
    builder: {
      accessories: 'Accessoires',
      accessoriesTypes: 'Types d\'accessoires',
      addAccessory: 'Ajouter un accessoire',
      manufacturerNotes: 'Note au fabricant',
      addToCart: 'Ajouter au panier',
      addToBundle: 'Ajouter à l\'ensemble',
      addTransformerType: 'Ajouter une charactéristique',
      aluminumProfiles: 'Profilés d\'aluminium',
      changeProfile: 'Changer de profilé',
      changeLedStrip: 'Changer de ruban DEL',
      presentation: 'Présentation',
      specifications: 'Spécifications',
      noSpecification: 'Aucune spécification',
      documentations: 'Documentation(s)',
      noDocumentation: 'Aucune documentation',
      noPresentation: 'Aucune présentation',
      downloadDocumentation: 'Télécharger la documentation',
      createBundle: 'Créer un ensemble',
      ledStrip: 'Rubans',
      ledStripQuantityTip: 'Pour les profilés plus larges, envisager plus de rubans. La compatibilité est validée avant de poursuivre.',
      selectForProfile: 'Sélectionner pour le profilé',
      addLedStrip: 'Ajouter un ruban',
      addProfile: 'Ajouter un profilé',
      quantity: 'Quantité',
      transformer: 'Transformateur',
      transformers: 'Transformateurs',
      selectTransformer: 'Sélectionnez un transformateur',
      priceAndTitle: 'Prix: {{value, price}}$',
      unitPriceAndTitle: 'Prix unitaire: {{value, price}}$',
      unitPriceAndTitleShort: 'Prix unit.: {{value, price}}$',
      defaultNote: 'Vous pouvez ajouter des indications à Titanium si nécessaire.',
      labor: 'Main d\'oeuvre',
      laborAndHandling: 'Main d\'oeuvre et manutention',
      productionTime: 'Durée d\'assemblage',
      productionPower: 'Puissance de l\'ensemble',
      power: 'Puissance',
      mountedInBox: 'Monté dans boîte',
      standard: 'Standard',
      dimmable: 'Gradable',
      plugin: 'Plugin',
      waterproof: 'Imperméable',
      optionalBoxAvailable: 'Boîte optionnelle disponible',
      interior: 'Intérieur',
      exterior: 'Extérieur',
      profileLength: 'Longueur du profilé',
      innerLength: 'Longueur interne',
      outerLength: 'Longueur externe',
      inches: 'Pouces',
      millimeters: 'Millimètres',
      feet: 'Pieds',
      unitShort_ft: 'pi',
      unitShort_in: 'po',
      unitShort_mm: 'mm',
      unitShort_m: 'm',
      unit: 'Unité',
      noTransformerResults: 'Aucun transformateur correspondant à vos critères n\'a été trouvé',
      powerCable: 'Câble d\'alimentation',
      length: 'Longueur',
      builtInBox: 'Boîte incluse',
      noBox: 'Sans boîte',
      approximate: 'Approximatif',
      voltage: 'Voltage',
      ip: 'IP',
      color: 'Couleur',
      watt: 'Watt',
      noAccessoriesForProfile: 'Ce profilé n\'a aucun accessoire',
      updateBundle: 'Mettre à jour l\'ensemble',
      updateProfile: 'Mettre à jour le profilé',
      enterProductCodeOrDescription: 'Entrez un code de produit ou une description du produit',
      cableExit: 'Sortie de fil',
      cableExitDescription: 'Sélectionnez le point de sortie du fil de votre profilé',
      extremity: 'Extrémité',
      under: 'Dessous',
      front: 'Avant',
      back: 'Arrière',
      viewFromTheLens: 'Vue de la lentille',
      buildProfile: 'Construisez votre profilé',
      finalizeEstimate: 'Finaliser l\'estimation',
      finalizeEstimateInCart: 'Finaliser l\'estimation du panier',
      cancel: 'Annuler',
      includeTransformer: 'Inclure le transformateur',
      buildingBundleNumber: 'Construction de l\'ensemble #{{value}}',
      buildingProfileNumber: 'Construction du profilé #{{value}}',
      addPowerCable: 'Ajouter un câble d\'allimentation',
      summary: 'Résumé',
      addTransformer: 'Ajouter un transformateur',
      notAddingTransformer: 'Aucun transformateur ne sera ajouté à l\'ensemble',
      selectAndAddPowerCables: 'Choisir et ajouter un cable d\'alimentation',
      cableLengthDescription: 'Indiquez la longueur du câble reliant le transformateur au profilé.',
      addAnotherProfile: 'Ajouter un autre profilé',
      selectAndAddTransformer: 'Choisir et ajouter un transformateur',
      selectProfile: 'Sélection profilé',
      selectLedStrips: 'Sélection rubans',
      selectPowerCable: 'Sélection câble',
      emptyEstimate: 'L\'estimation est vide',
      addAPowerCable: 'Ajouter un cable d\'alimentation',
      navigationEstimateDiscard: 'En quittant cette page, vous allez perdre l\'estimation en cours. Voulez-vous continuer?',
    },
    cart: {
      client: 'Client',
      referenceClientName: 'Nom du client',
      referenceNumber: 'Numéro de référence',
      shippingMode: 'Mode de livraison',
      carrierName: 'Nom du transporteur',
      accountNumber: 'Numéro de compte',
      addToBill: 'Ajouter à ma facture',
      shopPickup: 'Ramassage en magasin',
      myCarrier: 'Mon transporteur',
      shipToClient: 'Expédier au client',
      shippingPayment: 'Mode de paiement pour la livraison',
      paymentOption: 'Option de paiement',
      requestedShippingDate: 'Date de livraison demandée',
      billToAccount: 'Porté au compte',
      payableOnReception: 'Payable à la réception',
      payableOnReception5Percent: 'Payable à la réception 5%',
      orderSummary: 'Résumé de la commande',
      items: 'Items',
      discount: 'Escompte',
      shipping: 'Livraison',
      subTotal: 'Sous-total',
      GST: 'TPS',
      QST: 'TVQ',
      shippingDestination: 'Destination de livraison',
      myAddress: 'Mon adresse',
      otherAddress: 'Autre adresse',
      address: 'Adresse',
      postalCode: 'Code postal',
      city: 'Ville',
      provinceState: 'Province/État',
      country: 'Pays',
      addBundle: 'Ajouter un ensemble',
      deliveryDate: 'Date de livraison',
      noPreferences: 'Aucune préférence',
      approximateDate: 'Date approximative',
      downloadPurchaseOrder: 'Télécharger le bon de commande',
      sendPurchaseOrderTo: 'Envoyer le bon de commande à',
      sendPurchaseOrder: 'Envoyer le bon de commande',
      applyPriceMargin: 'Appliquer une marge de profit',
      email: 'Courriel',
      note: 'Note',
      invalidDateMessage: 'Format de date invalide',
      maxDateMessage: 'La date ne doit pas dépasser la date maximale',
      minDateMessage: 'La date doit être après la date minimale',
    },
    error: {
      ledStripVoltageMismatch: 'Deux ou plusieurs rubans DEL n\'ont pas le même voltage',
      ledStripWidthExcess:
        'La somme totale des largeurs des rubans DEL sélectionnés dépasse la largeur interne du profilé sélectionné',
      noCompatiblePowerCable: 'Aucun câble d\'alimentation compatible n\'a été trouvé pour le ruban DEL {{productCode}}',
      outOfRangeError: 'La valeur est en dehors de la plage des valeurs',
      invalidProductCode: 'Le code de produit est invalide',
      ledStripPowerCableMismatch:
        'Il y a une incohérence entre la quantité de ruban et la quantité de câble d\'alimentation',
      unknownStep: 'Étape inconnue',
      unknownError: 'Erreure inconnue',
      invalidEmail: 'Courriel invalide',
      unavailableProduct: 'Ce produit n\'est plus disponible',
      removingUnavailableProduct: 'Ce produit n\'est plus disponible et sera retiré de votre panier',
    },
    placeholder: {
      address: 'Ex: 837 Rue Saint-Joseph Est',
      postalCode: 'Ex: G1K 3C8',
      city: 'Ex: Québec',
      provinceState: 'Ex: Québec',
      country: 'Ex: Canada',
      carrierName: 'Ex: FedEx',
      numberMask: '###############',
    },
    settings: {
      ratio: 'Ratio',
      name: 'Nom',
      profit: 'Profit',
      addPriceMargin: 'Ajouter une marge de profit',
      selectedPriceMargin: 'Marge de profit sélectionnée',
      managePriceMargins: 'Gestion des marges de profit',
      changesSaved: 'Vos changements ont été sauvegardés',
      updatedToDefault: 'Votre marge de profit sélectionnée a été supprimée; elle a été changée à celle par défaut',
    },
  },
};

export default translations;
