import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { Colors, Fonts, Metrics } from '../utils';

const MuiThemeOptions: ThemeOptions = {
  colors: {
    ...Colors,
  },
  fonts: {
    ...Fonts,
  },
  metrics: {
    ...Metrics,
  },
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: Colors.accentLight66,
    },
    error: {
      main: '#d32f2f',
      dark: '#8e1d1d',
    },
  },
  typography: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    h6: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    caption: {
      fontSize: 13,
      color: '#000000',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 13,
      color: Colors.hint,
    },
  },
  customTypography: {
    paragraphTitle: {},
  },
  overrides: {
    MuiStepLabel: {
      label: {
        fontSize: 16,
        color: Colors.secondaryText,
        '&$active': {
          color: Colors.accent36,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        fontWeight: 900,
      },
      outlined: {
        fontSize: 13,
        borderColor: '#000000',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 24,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 13,
        color: Colors.hint,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 24px 20px',
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingBottom: 15,
        paddingTop: 15,
      },
    },
    MuiTypography: {
      caption: {
        display: 'block',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 24px 24px',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 18,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#ffffff',
        color: Colors.accent36,
        border: `1px solid ${Colors.accent36}`,
      },
      clickable: {
        '&:hover': {
          backgroundColor: Colors.accentHover72,
        },
        '&:focus': {
          backgroundColor: '#ffffff',
        },
      },
      deletable: {
        '&:hover': {
          backgroundColor: Colors.accent36,
        },
        '&:focus': {
          backgroundColor: Colors.accent36,
        },
      },
    },
    MuiDivider: {
      root: {
        margin: '20px 0px',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: Colors.accent36,
      },
    },
    MuiExpansionPanel: {
      root: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiStepper: {
      root: {
        padding: '20px 0',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      body: {
        fontSize: 16,
      },
      footer: {
        color: '#000000',
      },
    },
  },
};

export default MuiThemeOptions;
