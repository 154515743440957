import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/step.min.css';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <App />,
  document.getElementById('root') as HTMLElement,
);

serviceWorker.unregister();
