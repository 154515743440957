import mathjs from 'mathjs';
import { Unit } from 'src/builder/domain/Unit';

export default class Measure {
  public readonly value: number;
  public readonly unit: Unit;

  constructor(value: number, unit: Unit) {
    this.value = value;
    this.unit = unit;
  }

  public static toMeters(measure: Measure) {
    const { unit, value } = measure;

    const mmUnit = mathjs.unit(value, unit.toLowerCase());
    return mmUnit.toNumber(Unit.M.toLowerCase());
  }

  public static convertToMeters(measure: Measure) {
    const { unit, value } = measure;

    if (measure.unit === Unit.M) {
      return measure;
    }

    const mmUnit = mathjs.unit((mathjs.round(value, 6) as number), unit.toLowerCase());

    return new Measure(mmUnit.toNumber(Unit.M.toLowerCase()), Unit.M);
  }

  public static toFeet(measure: Measure) {
    const { unit, value } = measure;

    const mmUnit = mathjs.unit(value, unit.toLowerCase());
    return mmUnit.toNumber(Unit.FT.toLowerCase());
  }

  public static convertToFeet(measure: Measure) {
    const { unit, value } = measure;

    if (measure.unit === Unit.FT) {
      return measure;
    }

    const mmUnit = mathjs.unit((mathjs.round(value, 6) as number), unit.toLowerCase());

    return new Measure(mmUnit.toNumber(Unit.FT.toLowerCase()), Unit.FT);
  }

  public static toMillimeters(measure: Measure) {
    const { unit, value } = measure;

    const mmUnit = mathjs.unit(value, unit.toLowerCase());
    return mmUnit.toNumber(Unit.MM.toLowerCase());
  }

  public static toInch(measure: Measure) {
    const { unit, value } = measure;

    const mmUnit = mathjs.unit(value, unit.toLowerCase());
    return mmUnit.toNumber(Unit.IN.toLowerCase());
  }

  public static convertToInch(measure: Measure) {
    const { unit, value } = measure;

    if (measure.unit === Unit.IN) {
      return measure;
    }

    const mmUnit = mathjs.unit((mathjs.round(value, 6) as number), unit.toLowerCase());

    return new Measure(mmUnit.toNumber(Unit.IN.toLowerCase()), Unit.IN);
  }

  public static feetToMeters(foot: number): number {
    const mmUnit = mathjs.unit(foot, Unit.FT.toLowerCase());
    return mmUnit.toNumber(Unit.M.toLowerCase());
  }

  public static metersToFeet(meters: number): number {
    const mmUnit = mathjs.unit(meters, Unit.M.toLowerCase());
    return mmUnit.toNumber(Unit.FT.toLowerCase());
  }

  public static metersToMillimeters(value: number) {
    const mmUnit = mathjs.unit(value, Unit.M.toLowerCase());
    return mmUnit.toNumber(Unit.MM.toLowerCase());
  }
}
