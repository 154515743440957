import i18n from './index';

export const Locales = {
  french: 'fr-CA',
  english: 'en-CA',
};

export function toggleLanguage() {
  const currentLanguageCode = i18n.language.substr(0, 2).toLowerCase();
  const frenchLanguageCode = Locales.french.substr(0, 2).toLowerCase();

  if (currentLanguageCode === frenchLanguageCode) {
    i18n.changeLanguage(Locales.english);
  } else {
    i18n.changeLanguage(Locales.french);
  }
}
