import i18n, { InitOptions, Resource } from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import math from 'mathjs';
import moment from 'moment';
import { Unit } from 'src/builder/domain/Unit';
import Measure from 'src/shared/domain/Measure';
import NumberSeparator from 'src/shared/domain/NumberSeparator';
import translations from './translations';

require('moment/locale/en-ca.js');
require('moment/locale/fr-ca.js');

const detectorOptions: DetectorOptions = {
  order: ['localStorage', 'navigator'],
};

const options: InitOptions = {
  debug: false,
  whitelist: ['en', 'fr'],
  nonExplicitWhitelist: true,
  fallbackLng: 'en-CA',
  resources: translations as Resource,
  defaultNS: 'general',
  fallbackNS: 'general',
  interpolation: {
    escapeValue: false, // not needed for react
    format: formatter,
  },
  react: {
    defaultTransParent: 'span',
  },
  detection: detectorOptions,
  simplifyPluralSuffix: true,
};

function formatter(value: any, format?: string, lng?: string) {
  if (format === 'uppercase') {
    return value.toUpperCase();
  }
  if (moment.isMoment(value)) {
    return value.format(format);
  }
  if (typeof value === 'number') {
    if (format === 'inchFraction') {
      return inchFractionFormatter(value, getNearestFraction);
    }
    if (format === 'inchFractionLower') {
      return inchFractionFormatter(value, getNearestFractionLower);
    }
    if (format === 'inchFractionUpper') {
      return inchFractionFormatter(value, getNearestFractionUpper);
    }
    if (format === 'notFixedDigitsNumber') {
      return Number(value).toLocaleString(lng, { minimumSignificantDigits: 1 });
    }
    if (format === 'price') {
      return Number(value.toFixed(2)).toLocaleString(lng, { minimumFractionDigits: 2 });
    }
    return Number(value.toFixed(2));
  }

  if (format === 'measureInchesMeters') {
    const measure = value as Measure;
    return `${i18n.t('general:measure', { value: Measure.convertToInch(measure) })} | ${i18n.t('general:measure', { value: Measure.convertToMeters(measure) })}`;

  }

  if (format === 'measureFeetMeters') {
    const measure = value as Measure;
    return `${i18n.t('general:measure', { value: Measure.convertToFeet(measure) })} | ${i18n.t('general:measure', { value: Measure.convertToMeters(measure) })}`;
  }

  if (format === 'measure') {
    const measure = value as Measure;
    const valuePart = measure.unit === Unit.IN ?
      inchFractionFormatter(measure.value, getNearestFraction) :
      parseFloat(measure.value.toFixed(2)).toLocaleString(lng);

    return `${valuePart} ${i18n.t('builder:unitShort', { context: measure.unit.toLowerCase() })}`;
  }

  return value;
}

const getNearestFraction = (fraction: math.Fraction, denominator: number): math.Fraction => {
  return math.fraction(Math.round((fraction.n / fraction.d) * denominator), denominator) as math.Fraction;
};

const getNearestFractionLower = (fraction: math.Fraction, denominator: number): math.Fraction => {
  return math.fraction(Math.floor((fraction.n / fraction.d) * denominator), denominator) as math.Fraction;
};

const getNearestFractionUpper = (fraction: math.Fraction, denominator: number): math.Fraction => {
  return math.fraction(Math.ceil((fraction.n / fraction.d) * denominator), denominator) as math.Fraction;
};

function inchFractionFormatter(value: number, getFraction: (fraction: math.Fraction, denominator: number) => math.Fraction) {
  const { decimalValue, integerValue } = NumberSeparator.getIntegerDecimalValues(value);
  const fraction = math.format(getFraction(math.fraction(decimalValue) as math.Fraction, 16), { fraction: 'ratio' });
  return `${integerValue === 0 ? '' : `${integerValue.toString()}`}${fraction === '0/1' ? '' : ` ${fraction}`}`.trimStart();
}

i18n.use(LanguageDetector).init(options, () => {
  moment.locale(i18n.language);
});

i18n.on('languageChanged', (lng: string) => {
  moment.locale(lng);
});

export default i18n;
export * from './getCurrentLanguageDisplayCode';
export * from './toggleLanguage';
