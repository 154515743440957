import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client/ApolloClient';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import DebounceLink from 'apollo-link-debounce';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import PriceMargin from 'src/main/domain/PriceMargin';
import i18n from 'src/main/i18n';

const DEBOUNCE_TIME = 500;

export const defaultState = {
  authenticated: null,
  selectedPriceMargin: new PriceMargin(),
};

export default function ApolloClientBuilder() {

  const debounceLink = new DebounceLink(DEBOUNCE_TIME) as unknown as ApolloLink;

  const inMemoryCache = new InMemoryCache();

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions && extensions.code === 'UNAUTHENTICATED') {
          inMemoryCache.reset().then(() => {
            inMemoryCache.writeData({ data: { ...defaultState, authenticated: false } });
          });
        }
      });
    }
  });

  const headersLink = setContext((_, { headers }) => {
    const locale = i18n.language;

    return {
      headers: {
        ...headers,
        'x-locale': locale,
      },
    };
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'include',
  });

  const link = ApolloLink.from([debounceLink, errorLink, headersLink, httpLink]);

  const client = new ApolloClient({
    link,
    cache: inMemoryCache,
    connectToDevTools: true,
  });

  inMemoryCache.writeData({
    data: defaultState,
  });

  return client;
}
