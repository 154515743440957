const Metrics = {
  hairlineMargin: 1,
  minusculeMargin: 3,
  tinyMargin: 5,
  smallMargin: 10,
  normalMargin: 15,
  mediumMargin: 20,
  largeMargin: 30,
  xLargeMargin: 40,
  hugeMargin: 50,
  radius: {
    small: 2,
  },
  menu: {
    webHeight: 90,
    mobileHeight: 70,
    dialog: {
      width: 600,
    },
  },
  listItem: {
    small: 70,
  },
  configuration: {
    webHeight: 110,
    mobileHeight: 185,
    confirmationButton: 56,
    addressInfoWidth: 245,
    dialog: {
      title: 62,
      fullMinHeight: 525,
      fullMaxWidth: 400,
    },
    tab: {
      width: 92,
    },
  },
  orderType: {
    width: 130,
    height: 79,
  },
  button: {
    fab: {
      size: {
        web: 42,
        mobile: {
          width: 36,
          height: 24,
        },
      },
    },
    extendedFab: {
      size: {
        width: 100,
        height: 42,
      },
    },
  },
  responsive: {
    onlyTablet: 767,
    onlyComputer: 768,
  },
  icons: {
    size: {
      tiny: 10,
      xsmall: 20,
      small: 24,
      normal: 30,
      medium: 48,
    },
  },
  images: {
    size: {
      tiny: 15,
      small: 24,
      normal: 65,
      large: 70,
      xlarge: 80,
      huge: 160,
    },
  },
  opacity: {
    medium: 0.5,
  },
};

export default Metrics;
