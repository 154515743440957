import { FontStyleProperty, FontWeightProperty } from 'csstype';

function massive(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 38,
  };
}

function huge(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 28,
  };
}

function main(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 24,
  };
}

function secondary(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 20,
  };
}

function sub(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 17,
  };
}

function small(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 14,
  };
}

function xsmall(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 12,
  };
}

function xxsmall(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 10,
  };
}

function tiny(fontWeight: FontWeightProperty = 'normal', fontStyle: FontStyleProperty = 'normal') {
  return {
    fontStyle,
    fontWeight,
    fontSize: 6,
  };
}

export default {
  massive,
  huge,
  main,
  secondary,
  sub,
  small,
  xsmall,
  xxsmall,
  tiny,
};
