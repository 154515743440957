import MomentUtils from '@date-io/moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import ApolloClientBuilder from './infrastructure/ApolloClientBuilder';
import Startup from './main/containers/Startup';
import i18n from './main/i18n';
import MuiThemeOptions from './ui/theme/MuiThemeOptions';

const myTheme: Theme = createMuiTheme(MuiThemeOptions);

const client = ApolloClientBuilder();

class App extends React.Component {
  public render() {
    return (
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <MuiThemeProvider theme={myTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <CookiesProvider>
                <Startup />
              </CookiesProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </I18nextProvider>
      </ApolloProvider>
    );
  }
}

export default App;
